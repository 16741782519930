import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import css from './IconPlus.module.css';

const IconPlus = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {isOpen ? <IoIosArrowUp className={css.icon} /> : <IoIosArrowDown className={css.icon} />}
    </div>
  );
};

IconPlus.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

IconPlus.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default IconPlus;
